<template>
    <v-alert v-model="alert" color="cyan" border="left" elevation="2" colored-border :icon="this.icon">
        <div class="d-flex">
            {{ name }}
            <v-icon class="mr-1 ml-auto icon-link" @click="goToLink">mdi-link-variant</v-icon>
            <v-icon class="mr-0 icon-link" @click="deleteItem">mdi-delete</v-icon>
        </div>
    </v-alert>
</template>
<script>
import axios from "axios";

export default {
    name: "ItemViewDocument",
    props: {
        name: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-file-document"
        },
        field: {
            type: String,
            required: false,
            default: ""
        },
        table: {
            type: String,
            required: false,
            default: ""
        },
        id: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data(){
        return {
            alert: true
        }
    },
    methods: {
        goToLink() {
            window.open(this.url, '_blank');
        },
        deleteItem(){
            axios.post(`${process.env.VUE_APP_BHC_BASE_URL}/delete-document-entity`, 
            {
                field: this.field,
                table: this.table,
                id: this.id
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Token-Google-Authenticator': sessionStorage.getItem('2fa')
                }
            }).then(resp=>{
                if(resp.data.success){
                    this.$emit('reload');
                }
            }).catch(err=>console.log(err));
        }
    }
}
</script>
<style>
.icon-link{
    cursor: pointer;
}
</style>
