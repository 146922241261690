<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
                mdi-file-export
            </v-icon>
        </template>

        <span>Carga de documentos</span>

        <v-dialog v-model="dialog" fullscreen persistent>
            <v-card>
                <div>
                    <v-card-title class="text-h6 grey lighten-2">
                        <span class="headline">Carga de documentos</span>
                        <v-btn plain icon @click="close" class="ml-auto mr-0">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col lg="8" md="8" sm="12">
                            <v-form lazy-validation ref="form" v-model="validForm">
                                <v-card-text class="pt-2 pl-2 pr-2">
                                    <v-row>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>Identificaci&oacute;n oficial del promovente (propietario
                                                        y/o representante legal)</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">Puede presentar cualquiera de las
                                                    siguientes:</label>
                                                <ul>
                                                    <li class="subtitle-1">Credencial para votar vigente, expedida por
                                                        el Instituto Nacional Electoral (antes Instituto Federal
                                                        Electoral).</li>
                                                    <li class="subtitle-1">Pasaporte vigente.</li>
                                                    <li class="subtitle-1">C&eacute;dula profesional vigente con
                                                        fotograf&iacute;a. Quedan exceptuadas las c&eacute;dulas
                                                        profesionales electr&oacute;nicas.</li>
                                                </ul>
                                                <label class="subtitle-2">
                                                    <strong>En caso de las fotocopias o archivos digitalizados de
                                                        identificaciones oficiales (ej. credencial de elector,
                                                        c&eacute;dula, otros) se deber&aacute; incluir en una sola cara
                                                        de la copia ambos lados del documento original.</strong>
                                                </label>
                                            </div>
                                            <v-file-input accept="image/png, image/jpeg, .pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" placeholder="Parte de adelante"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                prepend-icon="mdi-camera" small-chips truncate-length="50"
                                                v-model="oficial_identity">
                                            </v-file-input>
                                            <v-file-input accept="image/png, image/jpeg, .pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" placeholder="Parte de atrás"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                prepend-icon="mdi-camera-outline" small-chips truncate-length="50"
                                                v-model="oficial_identity2"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div>
                                                <label class="title"><strong>T&iacute;tulo y c&eacute;dula profesional
                                                        del responsable
                                                        sanitario del establecimiento en formato PDF</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">Documentaci&oacute;n que acredita y
                                                    avala la formaci&oacute;n acad&eacute;mica y t&eacute;cnica de un
                                                    profesional de la medicina en M&eacute;xico, garantizando un alto
                                                    nivel de calidad y seguridad en la atenci&oacute;n m&eacute;dica que
                                                    se brinda a los pacientes.</label>
                                                <label class="subtitle-1 d-block">Cargue aqu&iacute; copia de su
                                                    c&eacute;dula profesional por ambos lados.</label>
                                            </div>
                                            <v-file-input accept=".pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="professional_cedula">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div>
                                                <label class="title">
                                                    <strong>Constancia del Registro Estatal de Establecimientos
                                                        Prestadores
                                                        de Servicios de Salud y Bienestar(REPSSABI) de la
                                                        COEPRIS</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">Acreditaci&oacute;n otorgada por la
                                                    Comisi&oacute;n Estatal de Protecci&oacute;n Contra Riesgos
                                                    Sanitarios del Estado de Baja California (COEPRIS), que avala haber
                                                    cumplido satisfactoriamente los criterios de capacidad, las mejores
                                                    pr&aacute;cticas sanitarias, la calidad y seguridad de los servicios
                                                    m&eacute;dicos que se brindan en Baja California</label>
                                                <label class="subtitle-1 d-block">Cargue aqu&iacute; copia de su
                                                    constancia del REPSSABI en formato PDF. Si no cuenta con la
                                                    constancia deber&aacute; de tramitarlo a trav&eacute;s de la
                                                    secci&oacute;n de tr&aacute;mites en l&iacute;nea de la COFEPRIS.
                                                </label>
                                            </div>
                                            <v-file-input accept=".pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                prepend-icon="mdi-clipboard-account" truncate-length="50"
                                                v-model="doc_repssabi_coepris">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div>
                                                <label class="title">
                                                    <strong>Certificaci&oacute;n y/o acreditaci&oacute;n (si
                                                        aplica)</strong>
                                                </label>
                                                <label class="d-block subtitle-1">Cargue aqu&iacute; el documento(s)
                                                    vigentes de la(s) certificaci&oacute;n y/o acreditaci&oacute;n en
                                                    formato PDF</label>
                                            </div>
                                            <v-file-input accept=".pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips prepend-icon="mdi-doctor"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="certification_or_accreditation">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div>
                                                <label class="title">
                                                    <strong>Fotograf&iacute;as del exterior del negocio</strong>
                                                </label>
                                                <label class="d-block subtitle-1">La fotograf&iacute;a ser&aacute; para
                                                    insertarla en el cat&aacute;logo de Excelencia de Turismo de Salud.
                                                    Cerci&oacute;rese de que la fotograf&iacute;a sea a color y
                                                    clara.</label>
                                                <label class="d-block subtitle-1">Cargue aqu&iacute; la
                                                    fotograf&iacute;a en formato JPG o PNG y no exceder el tamaño de
                                                    2MB.</label>
                                            </div>
                                            <v-file-input accept="image/png, image/jpeg"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips
                                                prepend-icon="mdi-file-document"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="ext_photo">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div>
                                                <label class="title">
                                                    <strong>Logotipo(s) de asociaci&oacute;n, consejo, colegio
                                                        m&eacute;dico,
                                                        entre otros (si aplica)</strong>
                                                </label>
                                                <label class="d-block subtitle-1">Cargue aqu&iacute; el o los logotipos
                                                    de la Asociaci&oacute;n, Consejo, Colegio M&eacute;dico (si aplica)
                                                    al cual se encuentra afiliado como miembro activo. Formato JPG o PNG
                                                </label>
                                            </div>
                                            <v-file-input accept="image/png, image/jpeg"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips prepend-icon="mdi-image"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="logo_entity">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div>
                                                <label class="title">
                                                    <strong>Constancia del Registro Estatal de Turismo de la
                                                        SECTURE(RET)</strong>
                                                </label>
                                                <label class="d-block subtitle-1">Cargue aqu&iacute; el documento
                                                    vigente en formato PDF</label>
                                            </div>
                                            <v-file-input accept=".pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips
                                                prepend-icon="mdi-account-check"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="ret_secture">
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="d-flex">
                                    <v-btn class="mr-2 ml-auto" color="info" @click="save" :loading="saveChanges"
                                        :disabled="loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX') || !valid || !validForm">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-col>
                        <v-col lg="4" md="4" sm="12">
                            <v-card>
                                <v-card-title>Documentos subidos</v-card-title>
                                <v-card-text>
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.oficial_identity"
                                        field="oficial_identity" :table="table"
                                        :name="itemCurrent.oficial_identity_name" :id="itemCurrent.id"
                                        :url="itemCurrent.oficial_identity" icon="mdi-camera" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.oficial_identity2"
                                        field="oficial_identity2" :table="table"
                                        :name="itemCurrent.oficial_identity2_name" :id="itemCurrent.id"
                                        :url="itemCurrent.oficial_identity2" icon="mdi-camera-outline" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.professional_cedula"
                                        field="professional_cedula" :table="table"
                                        :name="itemCurrent.professional_cedula_name" :id="itemCurrent.id"
                                        :url="itemCurrent.professional_cedula" icon="mdi-paperclip" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.doc_repssabi_coepris"
                                        :table="table" :id="itemCurrent.id" field="doc_repssabi_coepris"
                                        :name="itemCurrent.doc_repssabi_coepris_name"
                                        :url="itemCurrent.doc_repssabi_coepris" icon="mdi-clipboard-account" />
                                    <ItemViewDocument @reload="loadData"
                                        v-if="itemCurrent.certification_or_accreditation"
                                        field="certification_or_accreditation" :table="table" :id="itemCurrent.id"
                                        :name="itemCurrent.certification_or_accreditation_name"
                                        :url="itemCurrent.certification_or_accreditation" icon="mdi-doctor" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.ext_photo"
                                        :name="itemCurrent.ext_photo_name" :url="itemCurrent.ext_photo"
                                        field="ext_photo" :table="table" :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.logo_entity"
                                        :id="itemCurrent.id" :name="itemCurrent.logo_entity_name"
                                        :url="itemCurrent.logo_entity" icon="mdi-image" field="logo_entity"
                                        :table="table" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.ret_secture"
                                        :name="itemCurrent.ret_secture_name" :url="itemCurrent.ret_secture"
                                        icon="mdi-account-check" field="ret_secture" :table="table"
                                        :id="itemCurrent.id" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>
    </v-tooltip>
</template>
<script>
import ItemViewDocument from "@/components/form-documents-doctor/ItemViewDocument";

export default {
    name: "FormDocumentsStablishment",
    props: ["item", "services", "table"],
    components: {
        ItemViewDocument
    },
    data(){
        return {
            dialog: false,
            loading: false,
            saveChanges: false,
            itemCurrent: {},
            oficial_identity: null,
            oficial_identity2: null,
            professional_cedula: null,
            doc_repssabi_coepris: null,
            certification_or_accreditation: null,
            ext_photo: null,
            logo_entity: null,
            ret_secture: null,
            validForm: false,
            user: {},
        }
    },
    computed: {
        valid(){
            return this.oficial_identity || this.oficial_identity2 || this.professional_cedula || this.doc_repssabi_coepris || 
                   this.certification_or_accreditation || this.ext_photo || this.logo_entity || 
                   this.ret_secture;
        }
    },
    methods: {
        loadData(){
            this.loading = true;
            this.services.getRecord(this.item.id).then(resp=>{
                this.itemCurrent = resp.value;
            }).catch(()=>{
                this.snackText = "Ha ocurrido un error!";
                this.snackBar = true;
            }).finally(()=>{
                this.loading = false;
            })
        },
        openDialog(){
            this.dialog = true;
            this.loadData();
        },
        close(){
            this.dialog = false;
            this.resetForm();
        },
        resetForm(){
            this.oficial_identity = null;
            this.oficial_identity2 = null;
            this.professional_cedula = null;
            this.doc_repssabi_coepris = null;
            this.certification_or_accreditation = null;
            this.ext_photo = null;
            this.logo_entity = null;
            this.ret_secture = null;
            this.$refs.form.reset();
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.saveChanges = true;
                this.services.uploadDocument(this.item.id, this.oficial_identity, this.oficial_identity2, 
                    this.professional_cedula,
                    this.doc_repssabi_coepris, this.certification_or_accreditation,
                    this.ext_photo, this.logo_entity, this.ret_secture)
                    .then(resp => {
                        if (resp.data.success) {
                            this.resetForm();
                            this.snackText = "Documento guardado con éxito";
                            this.loadData();
                        }
                        else {
                            this.snackText = "Ha ocurrido un error!";
                        }
                    }).catch(() => {
                        this.snackText = "Ha ocurrido un error!";
                    }).finally(() => {
                        this.saveChanges = false;
                        this.snackBar = true;
                    });
            }
        }
    },
    mounted(){
        this.user = this.$store.getters.getUser;
    }
}
</script>
<style>
.cols-custom{
  border: 1px #dcdbdb solid;
  border-radius: 0.25rem !important;
  margin: 0.4rem;
  box-shadow: 4px 4px 4px #e3e2e2;
}
</style>
